.rosterItem {
  display: table-row;
  /* Use table-row display for the item */
  padding: 10px;
  /* margin-bottom: 10px; */
  cursor: pointer;
}

.checkbox,
.rosterItem>td {
  display: table-cell;
  /* Use table-cell display for checkbox and data cells */
  padding: 8px;
  /* Add padding to cells for spacing */
  border-bottom: 1px solid #ccc;
  /* Add bottom border to separate rows */
}

.checkbox {
  padding-right: 10px;
  /* Adjust padding for checkbox cell */
}

.nameContent {
  color: #007095;
}

.selectedNameContent {
  color: white;
}

.nameContentMain {
  text-decoration: underline;
}

.selectedBackground {
  background-color: #acbad4 !important;
  color: white;
}