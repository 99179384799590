.table_container{
    position: relative;
    display: flex;
    flex-direction: column;

    max-width: 1250px;
    overflow-x: scroll;
}

.search_ipp{
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    margin-bottom: 5px;
}

.search{
    margin-top: 4px;
}

.table_headers{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header>div{
    padding-left: 10px;
    background-color: #dddddd;
    height: 40px;
}

.status_header>div{
    justify-content: center;
}

/* Bold font for sort table header span */
.header>div>span{
    font-weight: bold;
}

.table_content{
    width: 100%;
}

.even_row>div>div{
    background-color: #efefef;
}

.table_pagination{
    margin-top: 10px;
}

.nothing_to_display{
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nothing_to_display>p{
    margin: 0;
}

.loading{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 0;
    /* bottom: 0; */
    left: 0;
    /* right: 0; */
    display: flex;
    justify-content: center;
    align-items: center;
}