.stepItem {
    margin-bottom: 20px;
}

.stepNumber {
    display: inline-block;
    color: #727272;
    border: 1px solid #ddd;
    width: 40px;
    line-height: 40px;
    height: 40px;
    border-radius: 100%;
    text-align: center;
    display: inline-block;
    font-size: 18px;
    font-weight: 100;
}

.itemContent {
    color: #3d3d3d;
    display: inline-block;
    font-weight: 300;
    font-size: 14px;
    vertical-align: top;
    padding-left: 11.6px;
    width: 90%;
}

.stepItem .list {
    padding-left: 75px;
}

.stepItem .list .listItem {
    font-size: 14px;
    font-weight: 300;
    color: #3d3d3d;
}

.blueText {
    color: #007E8D;
}