.hcOIGSAMCheckContainer {

  h1 {
    font-size: 35px;
    color: #007e8d;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin: 0;
  }

  h1::after {
    content: "";
    display: block;
    height: 1px;
    width: 132px;
    margin: 15px 0 40px 0;
    background: #007e8d;
  }

  .description {
    display: flex;
    flex-direction: row;
  }

  .description div {
    flex: 1;
  }

  .description div:first-child {
    margin-right: 30px;
  }

  .description ul li {
    list-style: disc;
  }

  .report {
    margin-top: 22px;
    /* margin-left: 15px; */
  }

  .reportQuery {
    display: flex;
    flex-direction: row;
  }

  .reportQuery select {
    margin-bottom: 0px !important;
  }

  .reportQuery .reportQueryDate {
    width: 20%;
    margin-right: 30px;
  }

  .reportQuery .reportQuerySchool {
    width: 50%;
    margin-right: 30px;
  }

  .reportQuery .reportQueryProgram {
    width: 50%;
  }

  .reportQuery .reportQueryProgram button {
    background: #007e8d;
    color: #ffffff;
    font-weight: 300;
    text-transform: uppercase;
    padding: 8px 20px;
    margin-top: 10px;
    font-size: 14px;
    letter-spacing: 1px;
    transition: background-color 300ms ease-out;
  }

  .reportQuery .reportQueryProgram button:hover {
    background: #aa9b73;
  }

  .excludedProviderList {
    margin-left: 40px;
  }

  .content {
    margin: 20px 0;
  }

  .reportQueryDate select option:first-child,
  .reportQuerySchool select option:first-child,
  .reportQueryProgram select option:first-child {
    display: none;
  }

  .sp {
    width: 64px;
    height: 64px;
    clear: both;
    margin: 20px auto;
  }

  .spCircle {
    border: 4px rgba(0, 0, 0, 0.25) solid;
    border-top: 4px rgba(0, 0, 0, 0.50) solid;
    border-radius: 50%;
    -webkit-animation: spCircRot .6s infinite linear;
    animation: spCircRot .6s infinite linear;
  }

  @-webkit-keyframes spCircRot {
    from {
      -webkit-transform: rotate(0deg);
    }

    to {
      -webkit-transform: rotate(359deg);
    }
  }

  @keyframes spCircRot {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }
}