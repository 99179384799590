.results_header_row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 22px;
}

.results_header_label{
    color: #007e8d;
}

.results_download{
    color: #aa9b73;
    text-decoration: none;

}

.results_download:hover{
    color: #007e8d;
}

.results_download{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    object-fit: contain;
}

.icon{
    height: 18px;
    width: 12px;
    margin-right: 5px;
}