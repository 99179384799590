.container {
  display: grid;
  grid-template-columns: 54px 1fr 1fr 1fr 136px;
  /* Must match header */
  align-items: center;
  max-width: 1150px;
  min-width: 1150px;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #EFEFEF;
}
  .checkboxContainer {
    width: 54px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .checkbox {
    width: 22px !important;
    height: 22px !important;
    border-radius: 6px;
    background: #F7F7F7;
    box-shadow: 0px 0px 4px 0px #00000040 inset;
    border: 2px solid transparent;
    cursor: pointer;
    appearance: none !important;
    position: relative;
  }

  .checkbox:hover {
    background: #C8DBE1;
    border: 1.5px solid #007E8D;
  }
  
  .checkbox:checked {
    background: #007E8D;
    border: 2px solid #007E8D;
  }
  
  .checkbox:checked::after {
    content: '\2713';
    color: white;
    font-size: 18px;
    font-weight: bolder;
    position: absolute;
    top: -6px;
    left: 2px;
  }
  
  .studentNameContainer {
    width: 264px;
    height: 58px;
    display: flex;
    align-items: center;
  }
  
  .studentNameText {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.52px;
    color: #007E8D;
    text-align: left;
  }
  
  .radioButtonContainer {
    width: 330px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .radioButton {
    width: 18px;
    height: 18px !important;
    border: 2px solid #007E8D;
    border-radius: 50%;
    background: transparent;
    cursor: pointer;
    appearance: none;
    outline: none;
    position: relative;
  }

  .radioButton:hover {
    background: #C8DBE1;
    border: 2px solid #007E8D;
  }
  
  .radioButton:checked {
    background: #007E8D;
    border: 2px solid #007E8D;
  }
  
  
.radioButton:checked::before {
    content: '';
    width: 7px;
    height: 7px;
    background: white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .radioButton:checked::after {
    content: '';
    width: 14px;
    height: 14px;
    background: #007E8D;
    border: 1px solid white;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .statusContainer {
    width: 136px;
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .statusButton {
    width: 116px;
    height: 35px;
    border-radius: 30px;
    background: #DFDFDF;
    color: #000;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 500;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease, color 0.3s ease;
  }
  
  .statusButton.initiate {
    background: #007E8D;
    color: white;
  }
  
  .statusButton:disabled {
    background: #f0f0f0;
    cursor: not-allowed;
  }
  
  