
.container {
  display: flex;
  flex-direction: column; 
  /* align-items: center;  */
  max-width: 150px; 
  /* margin: auto;  */
  padding: 0;
}

.square_button {
  background: #007e8d;
  color: #ffffff;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
  letter-spacing: 1px;
  width: 100%;
  max-width: 100%; 
  margin-top: 10px; 
  display: flex;
}

.hover:hover {
  background-color: #aa9b73; 
}



