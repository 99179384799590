.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

.radioContainer {
  display: flex;
  /* align-items: center; */
}

.testsContainer {
  display: flex;
  flex-direction: column;
}

.testContainer {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.testRow {
  margin-top: 10px;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  gap: 10px;
}

.testItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
  width: 350px;

  label {
    margin: 0;
  }

  .testItem2 {
    width: 137px;
    display: flex;
    justify-content: space-between;
  }
}

.testItem2 div:first-child {
  padding-bottom: 15px;
}

.testColumn {
  display: flex;
  flex-direction: column;
}

.errorMessages {
  padding-top: 10px;
  color: #CC0000;
  font-weight: 300;
  font-family: "Roboto", sans-serif;
}