.student_info {
    max-width: 850px;
    margin-bottom: 10px;
}

.header {
    margin-bottom: 10px;
}

.info_row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 10px;
}

.info_item {
    display: flex;
    flex-direction: column;
    min-height: 50px;
}

.info_item>p {
    margin-bottom: 0;
}

.info_data {
    color: #007e8d;
}

.elip_data {
    color: #007e8d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip_container {
    position: relative;
}

.hidden {
    display: none;
}

.active {
    display: flex;
    position: absolute;
    z-index: 1000;
    transform: translateY(50px);
    word-break: break-all;
    background-color: #007e8d;
    color: white;
    width: 100%;
    padding: 3px 6px;
    border-radius: 5px;
}

/* .stickyHeader {
    position: sticky;
    top: 0;
    background-color: rgba(159, 186, 76, 0.8);
    opacity: 0.8;
    z-index: 1000;
    padding: 10px;
    font-weight: bold;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3{
        margin-bottom: 0px;
    }
} */

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 250px;
}

.modal_content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.modal h3 {
    margin-bottom: 10px;
}

.modal ul {
    max-height: 200px;
    overflow-y: auto;
    list-style: none;
    padding: 0;
    margin-bottom: 10px;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

/* Scrollbar styles */
.modal ul::-webkit-scrollbar {
    display: none;
}

.modal ul::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.modal ul::-webkit-scrollbar-track {
    background: transparent;
}

.modal ul li {
    padding: 5px 0;
}

.modal button {
    background: #007e8d;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
}

.modal button:hover {
    background: #005f67;
}