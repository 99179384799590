.container {
  display: flex;
  flex-direction: column;
  gap: 0;
  /* width: 100%; */
  max-width: 1150px;
    min-width: 1150px;
  /* Prevents extreme shrinking */
  margin: 0 auto;
  /* Centers the list when possible */
}
  
 .divider {
   height: 1px;
   background-color: #EFEFEF;
   /* width: 165%; */
   /* Full viewport width */
   /* max-width: 100%; */
   /* Prevents overflow */
 }
.scroll-wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}