.container {
  position: relative;
  display: inline-block;
  /* Ensures it wraps around the checkbox */
}

.popup {
  position: absolute;
  left: -260px;
  /* Moves popup to the left */
  top: -20px;
  /* Moves it slightly above */
  background-color: #f7f4f4;
  border: 1px solid #E1E1E1;
  color: #000000;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  padding: 12px;
  max-width: 250px;
  height: auto;
  font-size: 14px;
  line-height: 1.2;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  z-index: 99999;
  /* Ensures it stays above everything */
  white-space: normal;
  visibility: visible;
  opacity: 1;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.popup.hidden {
  visibility: hidden;
  opacity: 0;
}