.reviewItemRow {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 50px;

  .col {
    /* display: flex; */
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    height: 100%;
    white-space: pre-wrap;
  }

  .textCenter {
    justify-content: center;
    text-align: center;
  }

  .button {
    background-color: #007e8d;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 300ms ease-out;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    padding: 0px 10px;
    font-size: 14px;
    letter-spacing: 1px;
    width: 60px;
    height: 30px;

  }

  .button:hover {
    background: #aa9b73;
  }
}

.reviewItemRow>div>p {
  margin: 0;
}

.link {
  color: #007e8d;
}

.iconCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}