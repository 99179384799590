.select_container{
  max-width: 350px;
}

/* the select will take up space above the select element if the view is blocked by the bottom of the screen
  Added this to showcase the variation in storybook
*/
.dropup{
  margin-top: 500px;
}

.selectBoxComponent {
  text-transform: none;
  appearance: none !important;
  border-radius: 0;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  padding: 0.5rem;
  line-height: normal;
  height: 2.3525rem;
  text-indent: 0.01px !important;
  text-overflow: '' !important;
  cursor: pointer !important;
  color: #3d3d3d;
  font-weight: 300;
  font-size: 13px;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
}

.select_active{
  color: #007e8d;
  border: 1px solid black;
  border-bottom: none;
}

.selectBoxComponent option {
  padding: 8px;
}

.custom_tip{
  max-width: 300px;
  padding: 5px;
  font-size: .65rem !important;
  animation: animateTool 0.5s;
}

@keyframes animateTool {
  0%{
      opacity: 0;
  }
  75%{
      opacity: 0;
  }
  90%{
      opacity: 1;
  }
  100%{
      opacity: 1;
  }
}