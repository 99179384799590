.container h1 {
    font-size: 35px;
    color: #007e8d;
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    margin: 0;
  }
  
  .container h1::after {
    content: "";
    display: block;
    height: 1px;
    width: 132px;
    margin: 15px 0 40px 0;
    background: #007e8d;
  }
  
  .description {
    display: flex;
    flex-direction: row;
  }
  
  .description div {
    flex: 1;
  }
  
  .description div:first-child {
    margin-right: 30px;
  }
  
  .description ul li {
    list-style: disc;
  }

  .excludedProviderList {
    margin-left: 40px;
  }
  
  .report {
    margin-top: 22px;
    margin-left: 15px;
  }
  
  .reportQuery {
    display: flex;
    flex-direction: row;
  }
  
  .reportQuery .reportQueryDate {
    width: 147px;
    margin-right: 30px;
  }
  
  .reportQuery .reportQuerySchool {
    width: 412px;
    margin-right: 30px;
  }
  
  .reportQuery .reportQueryProgram {
    width: 412px;
  }
  
  .reportQuery .reportQueryProgram .btnRunReport {
    background: #007e8d;
    color: #ffffff;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    padding: 5px 20px;
    font-size: 14px;
    letter-spacing: 1px;
  }
  