.assignmentItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.location {
  flex: 1;
  text-align: left;
}

.dates {
  display: flex;
  justify-content: flex-end;
  flex: 1;
  text-align: right;
}

.date {
  margin-left: 20px;
}
