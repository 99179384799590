.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  flex: 1;
  position: relative;
  padding-top: 20px;
}

.column {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  max-width: 550px;
}

.row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;
}

.paragraph {
  margin-bottom: 0px;
  font-weight: bold;
  padding-left: 10px;
}

.fileEditor {
    margin: 0px;
}

.custom_button {
  margin: 15px 5px;
  border-radius: 5px;
  width: 80px;
  align-self: flex-end;
}

.borderRight {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-right: 1px solid #cccccc;
  /* margin: 25px; */
}

.hr {
  width: 100%;
}

ul {
    padding: 0px;
}

.column > h3 {
  margin: 0px !important;
}

.container {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  flex: 1;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;
}

.paragraph {
  margin-bottom: 0px;
  font-weight: bold;
  padding-left: 10px;
}

.custom_button {
  margin: 15px 5px;
  border-radius: 5px;
  width: 80px;
  align-self: flex-end;
}

.borderRight {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  border-right: 1px solid #cccccc;
  /* margin: 25px; */
  /* height: 400px; */
}

.fileSetup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px;
}

hr {
  width: 100%;
}

ul {
    padding: 0px;
}

.column > h3 {
    margin: 0px;
}

.instructions {
display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 25px;
  margin-bottom: 25px;
  max-width: 500px;
}

.uploadRow {
    display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 0px;
}

.innerColumnContainer {
  display: flex;
  flex-wrap: wrap;
}

.innerColumn {
  flex: 1;
  padding: 10px;
}

.text_area {
  resize: none;
  overflow-y: scroll;
  text-wrap: wrap;
  height: 150px;
  min-width: 300px;
}

.error{
  color: #cc0000;
}

.loading{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  top: 0;
  /* bottom: 0; */
  left: 0;
  /* right: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.column .columnLocation {
  width: 100%;
}

.columnImmunizationDate label{
  text-wrap: nowrap;
}

.customContainer {
  width: 100%;
  min-width: 900px;
}

.column1{
  width: 60%;
}
.column3{
  max-width: 30%;
}