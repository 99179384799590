/* Even though this is defined below, Im placing it here as a test on prod */
/* Could be an issue of how this file is compiled, given the layered css styles */
.inputRow{
  display: flex !important;
}

.layout {
  display: flex;
  flex-direction: column;

  .mainContainer {
    .inputRow {
      display: flex !important;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .question_toggle>div>div>div{
      display: flex;
    }
    .question_toggle>div>div>div>label{
      margin-top: 7px !important;
    }

    label {
      white-space: nowrap;
    }

    .inputBox {
      display: flex;
      flex-direction: column;
      padding: 0 10px;
    }

    .inputBox input {
      margin-bottom: 5px !important;
    }
    .wp50 {
      flex: 1 1 50%;
    }
    .wp40 {
      flex: 1 1 40%;
    }
    .wp35 {
      flex: 1 1 35%;
    }
    .wp25 {
      flex: 1 1 25%;
    }
    .wp20 {
      flex: 1 1 20%;
    }
    .wp15 {
      flex: 1 1 15%;
    }
    .wp55 {
      flex: 1 1 55%
    }

    .customState>select{
      height: 42px;
      max-height: 42px;
      margin-bottom: 0;
      margin-top: 1px;
    }

    .hasPreviousNames {
      margin-left: 1px;
    }

    .textCenter {
      text-align: center;
    }

    hr {
      border: solid #dddddd;
      border-width: 1px 0 0;
      clear: both;
      margin: 20px 0;
      height: 0;
    }

    button {
      background: #007e8d;
      color: #ffffff;
      font-weight: 300;
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
      padding: 10px 20px;
      font-size: 14px;
      letter-spacing: 1px;
      transition: background-color 300ms ease-out;
      cursor: pointer;
    }

    button:hover {
      background: #aa9b73;
    }

    .infoSection div {
      padding-left: 0px !important;
    }

    .controlsSection div {
      padding-left: 0px !important;
    }

    button.buttonAddAlias {
      color: #3d3d3d;
      text-decoration: underline;
      font-size: 11px;
      background: none;
      text-align: center;
      text-transform: none;
      margin: 15px 0;
      font-weight: 300;
      font-family: "Roboto", sans-serif;
      letter-spacing: 1px;
      border: none;
    }

    button.buttonAddAlias:before {
      content: "+ ";
      display: inline-block;
    }

    button.buttonAddAlias:hover {
      color: #007e8d;
    }

    .label.success {
      background-color: #43ac6a;
      color: #ffffff;
    }

    .label.error {
      background-color: #b33f22;
      color: #ffffff;
    }

    .label.round {
      border-radius: 1000px;
    }

    .label {
      font-weight: normal;
      font-family: "Source Sans Pro", sans-serif;
      text-align: center;
      text-decoration: none;
      line-height: 1;
      white-space: nowrap;
      display: inline-block;
      position: relative;
      margin-bottom: auto;
      padding: 0.25rem 0.5rem 0.25rem;
      font-size: 0.6875rem;
      margin-left: 5px;
    }

    .label.round {
      border-radius: 1000px;
    }

    span.error,
    small.error {
      display: block;
      margin-top: -1px;
      font-size: 0.75rem;
      font-weight: normal;
      color: #ffffff;
      background: none;
      color: #e00000;
    }
    .buttonSubmitRow{
      display: flex;
      height: 44px;
      /* margin-bottom: 10px; */
    }
  }
}
