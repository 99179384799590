.searchContainer {
    display: flex;
    justify-content: center;
    gap: 135px;
    margin-bottom: 15px;
}

@media (max-width: 1024px) {
    .searchContainer {
        flex-wrap: wrap;
        justify-content: center;
        gap: 8px;
    }
}

@media (max-width: 768px) {
    .searchContainer {
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
}