.container {
  display: grid;
  grid-template-columns: 54px 1fr 1fr 1fr 136px;
  align-items: center;
  width: 100%;
  max-width: 1150px;
    min-width: 1150px;
  height: 58px;
  background: #EFEFEF;
  padding: 10px 0;
  margin: 0 auto;
  border-bottom: 1px solid #D0D0D0;
  position: sticky;
  top: 0;
  z-index: 10;
  /* Ensures it stays above the content */
}
  /* Checkbox Container */
     .checkboxContainer {
       width: 54px;
       height: 58px;
       display: flex;
       align-items: center;
       justify-content: center;
     }
  
     .checkbox {
       width: 22px !important;
       height: 22px !important;
       border-radius: 6px;
       background: #F7F7F7;
       box-shadow: 0px 0px 4px 0px #00000040 inset;
       border: 2px solid transparent;
       cursor: pointer;
       appearance: none !important;
       position: relative;
     }
  
     .checkbox:hover {
       background: #C8DBE1;
       border: 1.5px solid #007E8D;
     }
  
     .checkbox:checked {
       background: #007E8D;
       border: 2px solid #007E8D;
     }
  
     .checkbox:checked::after {
       content: '\2713';
       color: white;
       font-size: 18px;
       font-weight: bolder;
       position: absolute;
       top: -6px;
       left: 2px;
     }
  /* Student Name Container */
  .studentNameContainer {
    width: 264px;
    height: 58px;
    display: flex;
    align-items: center;
  }
  
  /* Student Name Text */
  .studentNameText {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #3D3D3D;
    text-align: left;
  }
  
  /* First Category Header Container */
  .firstCategoryHeaderContainer {
    width: 330px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* First Category Header Text */
  .firstCategoryHeaderText {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #3D3D3D;
    text-align: center;
  }
  
  /* Second Category Header Container */
  .secondCategoryHeaderContainer {
    width: 330px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Second Category Header Text */
  .secondCategoryHeaderText {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #3D3D3D;
    text-align: center;
  }
  
  /* Status Container */
  .statusContainer {
    width: 136px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Status Text */
  .statusText {
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.52px;
    color: #3D3D3D;
    text-align: center;
  }/* Tooltip Wrapper for Checkbox */
 /* Tooltip Wrapper for Checkbox */
 .tooltipWrapper {
   position: relative;
   display: flex;
   align-items: center;
 }

 /* Hidden Tooltip by Default */
 .tooltip {
   position: absolute;
   right: 110%;
   /* Moves tooltip to the left of the checkbox */
   top: 50%;
   transform: translateY(-50%);
   background-color: #333;
   color: white;
   padding: 6px 10px;
   font-size: 12px;
   border-radius: 4px;
   white-space: nowrap;
   opacity: 0;
   visibility: hidden;
   transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
 }

 /* Show Tooltip on Hover */
 .tooltipWrapper:hover .tooltip {
   opacity: 1;
   visibility: visible;
 }