.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  padding-right: 20px;
}

.iconContainer {
  display: grid;
  grid-template-columns: auto;
  margin-left: 25px; /* Adjust as needed */
}

.iconContainer > * {
  margin-top: 0.1px; /* Adjust as needed */
}

.active {
  color: darkgray; /* Adjust color as needed */
}

.inactive {
  color: lightgray; /* Adjust color as needed */
}
