.record_requirements_container{
    max-width: 1250px;
}

.key{
    display: flex;
    flex-wrap: wrap;
}

.key_item{
    display: flex;
    margin-right: 20px;
}

.bold{
    font-weight: bold;
}

@media screen and (max-width: 450px) {
    .table>div:nth-child(1){
        flex-direction: column;
    }
}