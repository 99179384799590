.seflPayContainer {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
  background-color: transparent;
}

.paymentForm {
  background: #fff;
  padding: 20px;
  /* border-radius: 8px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-width: 400px;
  width: 100%;
  /* box-sizing: border-box; */
  display: flex;
  flex-direction: column;
}

.paymentForm h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

.paymentForm label {
  display: block;
  margin-bottom: 5px;
}

.paymentForm input,
.paymentForm select,
.paymentForm button {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.paymentForm button {
  transition: background-color 300ms ease-out;
  background-color: #007e8d;
  color: white;
  border: none;
  cursor: pointer;
  width: 20%;
}

.paymentForm button:hover {
  background-color: #aa9b73;
}

.paymentForm select option:first-child {
  display: none;
}