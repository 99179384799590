.container {
  display: flex;
  flex-direction: column;
}

.container label {
    font-size: 14px;
    font-weight: 300;
    color: #3d3d3d;
    margin: 10px 0 0;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    line-height: 1.5;
}

.items {
  display: flex;
}
