.learning_list_wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    max-width: 750px;
}

.learning_list_item {
    width: 50%;
}

@media screen and (max-width: 750px){
    .learning_list_wrapper{
        flex-direction: column;
    }
}