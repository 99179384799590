
.keyContainer {
    display: flex;
    width: 50%;
  }
  .keyContainer li {
    width: 100%;
  }
  .tableKeyContainer {
    display: flex;
  }
  
  .faHourGlassIcon {
    color: #aa9b73;
  }
  
  .faPlusIcon {
    color: #97ba36;
  }
  
  .faMinusIcon {
    color: #e00000;
  }
  .clickHereAnchor {
    color: #007e8d;
    text-decoration: none;
    outline: none;
    border: none;
  }
  .clickHereAnchor:hover {
    color: #aa9b73;
  }
  
  .assignmentPaginationRow {
    padding-top: 30px;
  }