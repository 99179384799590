.passportAdminReviewTableContainer {
  max-width: 100%;
  overflow-x: auto;
  font-size: 12px;


  .passportAdminReviewTableHeader {
    display: flex;
    flex-direction: row;
    /* margin-top: 30px; */
  }

  .passportAdminReviewTableHeaderCol {
    display: flex;
    flex-direction: row;

    font-weight: bold;
    height: 50px;
    background-color: #ebebeb;
  }

  .passportAdminReviewTableHeaderCol>div>div {
    margin: 0;
  }

  .passportAdminReviewTableHeaderCol>div {
    padding: 10px;
  }

  .rowItem {
    display: flex;
    width: 100%;
  }

  .passportAdminReviewTableContentEven>div>div {
    background-color: #f3f3f3;
  }

  .passportAdminReviewTableHeaderCol span {
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .textCenter {
    justify-content: center;
    text-align: center;
  }

  .button {
    background-color: #007e8d;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 300ms ease-out;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    padding: 0px 20px;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 30px 0px 20px 0px;
    height: 36px;
  }

  .button:hover {
    background: #aa9b73;
  }

  .reviewNoDataCol {
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .requirementNoDataCol>p {
    margin: 0;
  }

  .passportAdminReviewTableFooter {
    margin: 10px 0;

    .link {
      color: #007e8d;
    }
  }

  .passportAdminReviewFilter {
    display: flex;
    flex-wrap: wrap;

    .filterCol {
      flex: 1;
      margin-left: 30px;
      margin-right: 30px;
    }

    .filterCol:first-child {
      margin-left: 2px;
    }
  
    .filterCol:last-child {
      margin-right: 2px;
    }
  }
}