*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  /* max-width: 700px; */
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.7;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
  color: #3d3d3d;
  font-family: "Roboto", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


input[type="text"],
input[type="password"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="time"],
input[type="url"],
input[type="color"],
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  background-color: #FFFFFF;
  font-family: inherit;
  border-style: solid;
  border-width: 1px;
  border-color: #cccccc;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.75);
  display: block;
  font-size: 0.875rem;
  margin: 0 0 0 0;
  padding: 0.5rem;
  height: 2.3125rem;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus {
  box-shadow: 0 0 5px #999999;
  border-color: #999999;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="date"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="time"]:focus,
input[type="url"]:focus,
input[type="color"]:focus,
textarea:focus {
  background: #fafafa;
  border-color: #999999;
  outline: none;
}

input[type="text"]:disabled,
input[type="password"]:disabled,
input[type="date"]:disabled,
input[type="datetime"]:disabled,
input[type="datetime-local"]:disabled,
input[type="month"]:disabled,
input[type="week"]:disabled,
input[type="email"]:disabled,
input[type="number"]:disabled,
input[type="search"]:disabled,
input[type="tel"]:disabled,
input[type="time"]:disabled,
input[type="url"]:disabled,
input[type="color"]:disabled,
textarea:disabled {
  background-color: #DDDDDD;
  cursor: default;
}

input[type="text"][disabled],
input[type="text"][readonly],
fieldset[disabled] input[type="text"],
input[type="password"][disabled],
input[type="password"][readonly],
fieldset[disabled] input[type="password"],
input[type="date"][disabled],
input[type="date"][readonly],
fieldset[disabled] input[type="date"],
input[type="datetime"][disabled],
input[type="datetime"][readonly],
fieldset[disabled] input[type="datetime"],
input[type="datetime-local"][disabled],
input[type="datetime-local"][readonly],
fieldset[disabled] input[type="datetime-local"],
input[type="month"][disabled],
input[type="month"][readonly],
fieldset[disabled] input[type="month"],
input[type="week"][disabled],
input[type="week"][readonly],
fieldset[disabled] input[type="week"],
input[type="email"][disabled],
input[type="email"][readonly],
fieldset[disabled] input[type="email"],
input[type="number"][disabled],
input[type="number"][readonly],
fieldset[disabled] input[type="number"],
input[type="search"][disabled],
input[type="search"][readonly],
fieldset[disabled] input[type="search"],
input[type="tel"][disabled],
input[type="tel"][readonly],
fieldset[disabled] input[type="tel"],
input[type="time"][disabled],
input[type="time"][readonly],
fieldset[disabled] input[type="time"],
input[type="url"][disabled],
input[type="url"][readonly],
fieldset[disabled] input[type="url"],
input[type="color"][disabled],
input[type="color"][readonly],
fieldset[disabled] input[type="color"],
textarea[disabled],
textarea[readonly],
fieldset[disabled] textarea {
  background-color: #DDDDDD;
  cursor: default;
}

input[type="text"].radius,
input[type="password"].radius,
input[type="date"].radius,
input[type="datetime"].radius,
input[type="datetime-local"].radius,
input[type="month"].radius,
input[type="week"].radius,
input[type="email"].radius,
input[type="number"].radius,
input[type="search"].radius,
input[type="tel"].radius,
input[type="time"].radius,
input[type="url"].radius,
input[type="color"].radius,
textarea.radius {
  border-radius: 3px;
}

button.disabled,
button[disabled],
.button.disabled,
.button[disabled] {
  background-color: #008CBA;
  border-color: #007095;
  color: #FFFFFF;
  cursor: default;
  opacity: 0.7;
  box-shadow: none;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  position: fixed !important; 
}