.tableContainer {
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  min-width: 400px;
}

.fileTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  border: 1px solid #e5e5e5;
}

.fileTable>thead>tr>th>div{
  padding-right: 0;
}

.fileTable th,
.fileTable td {
  padding: 0px 10px;
}

.fileTable th {
  background-color: #f5f5f5;
  text-align: center;
}

.noData {
  text-align: center;
  padding: 20px;
  color: #888;
}
