.container {
  display: flex;
}

.container li {
  list-style: none;
}

.tabList {
  display: flex;
  width: 100%;
  padding: unset !important;
  margin: unset !important;
}

.tabItem {
  display: inline-block !important;
  margin-right: 1px;
  background-color: #efefef;
  min-width: 141px;
  max-width: 230px;
}

.tabItem:last-child {
  margin-right: 0;
}

.tabItem > a {
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 25.6px;
  outline: none;
  display: block;
  color: #222222 !important;
  font-style: normal;
  padding: 1rem 2rem;
  font-family: "Source Sans Pro", sans-serif;
  cursor: pointer;
}

.active {
  background-color: #ffffff !important;
  border-top: 1px solid #007e8d;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.tabItem:not(.active):hover {
  background-color: #e1e1e1;
}
