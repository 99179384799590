.clinicalRosterTableContainer {
  .filteringButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-weight: 400;
    margin: 5px;
  }

  .selectItems {
    display: flex;
    justify-content: space-around;
    width: 100%;

  }

  .selectItem {
    width: 100%;
  }

  .selectItem>div:first-child {
    max-width: unset !important;
    padding-right: 40px;
  }

  .selectItem>div:first-child select {
    margin-bottom: 0px !important;
  }

  .selectItems>div:last-child>div:first-child {
    padding-right: 0px;
  }

  .searchBox {
    display: flex;
    align-items: center;
    float: right;
    margin: 0px;
  }

  .searchBox>div:first-child {
    padding-right: 0px !important;
  }

  .itemsPerPage {
    display: flex;
    align-items: center;
    float: left;
    margin: 5px;
  }

  .showAllButton {
    background-color: #008CBA;
    cursor: pointer;
    margin: 10px;
    display: inline-block;
    font-weight: 400 !important;
    padding: 8px 20px 8px 20px;
  }

  .showSelectedButton {
    background-color: #008CBA;
    cursor: pointer;
    margin: 10px;
    display: inline-block;
    font-weight: 400 !important;
    padding: 8px 20px 8px 20px;
  }

  .exportButton {
    background-color: #008CBA;
    cursor: pointer;
    margin: 10px;
    display: inline-block;
    font-weight: 400 !important;
    padding: 8px 20px 8px 20px;
  }


  .exportButton:hover,
  .showAllButton:hover,
  .showSelectedButton:hover {
    background-color: #007095;
    transition: background-color 300ms ease-out;
  }

  .rosterTable {
    width: 100%;
    border-collapse: collapse;
  }

  .rosterTable th,
  .rosterTable td {
    /* border: 1px solid #ddd; */
    padding: 0 0 0 10px;
    text-align: left;
  }

  .rosterTable th {
    background-color: #f2f2f2;
  }

  .rosterTable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .space {
    margin-top: 10px;
  }

  .tableContainer {
    overflow-x: auto;
    width: 100%;
  }

  .tableContainer table {
    width: 100%;
    border-collapse: collapse;
  }

  thead {
    border: solid 1px #DDDDDD !important;
  }

  tbody {
    border: solid 1px #DDDDDD !important;
  }

  tbody tr,
  tbody td {
    border: none !important;
  }

  thead tr {
    height: 37px !important;
    /* Adjust the height as needed */
  }

  thead span {
    font-size: 12px;
  }

  tbody tr {
    height: 37px !important;
    /* Adjust the height as needed */
  }

  input {
    height: auto !important;
  }
}