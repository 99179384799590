.statusContent{
    text-decoration: none;
}
.statusReview {
    color: #aa9b73; 
}
.statusRejected {
    color: #e00000; 
}
.statusAccepted {
    color: #97ba36; 
}
.faFolderIcon {
    color: #007e8d;
    cursor: pointer;
}
.faMinusIcon {
    color: #e00000;
}
.faPlusIcon {
    color: #97ba36;
}
.nameContent
{
    color: #007095; 
}
.tableCell{
    padding: 0.5625rem 0.625rem;
}
.selectedBackground{
    background-color: #acbad4 !important;
    color: white; 
}
.selectedStatusContent{
    color: white; 
}
.selectedNameContent{
    color: white; 
}
.checkbox{
  padding: 0px 10px;  
}
.nameContentMain {
    text-decoration: underline;
}
.custom_height{
    height: 30px;
}
.hidden_half_modal{
    display: none;
}
.half_modal {
    position: absolute;
    max-width: 400px;
    min-width: 300px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 900;
}
.half_modal::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 20px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    z-index: 901;
}
.half_modal::after {
    content: '';
    position: absolute;
    top: -11px;
    left: 20px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #ccc;
    z-index: 900;
}
.radioGroup{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.radioGroup>div{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.radioGroup>div>label{
    margin-top: 0;
}
.saveButton {
    background: #007e8d;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-bottom: 30px;
    border-radius: 1px;
    cursor: pointer;
    margin-left: 10px;
}
.textareaStyle {
    height: auto !important;
}
.alignRight {
    align-self: flex-end !important;
}

.hidden_lm_half_modal{
    display: none;
}
.half_lm_modal{
    position: absolute;
    width: auto;
    display: flex;
    transform: translateX(-220px);
    flex-direction: column;
    background-color: #ffffff;
    border: 1px solid #ccc;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 900;
}
.half_lm_modal::before {
    content: '';
    position: absolute;
    top: -10px;
    left: 225px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff;
    z-index: 901;
}
.half_lm_modal::after {
    content: '';
    position: absolute;
    top: -11px;
    left: 225px;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    border-bottom: 11px solid #ccc;
    z-index: 900;
}
.modalContent {
    background: #fff;
    display: flex;
    flex-direction: column;
    max-width: 500px; /* Adjust as needed */
    min-width: 400px;
    width: 100%;
}
  
.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
  
.modalHeader h2 {
    color: #333;
    font-size: 25px;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    text-rendering: optimizeLegibility;
    letter-spacing: 1px;
    padding-bottom: 30px; /* Adjust as needed */
    margin: 0;
  }
  
.modalBody {
    border: 1px solid #ccc;
}
  
.moduleList {
    list-style: none;
    padding: 0;
    margin: 0;
}
  
.moduleItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 5px;
}
  
.even {
    background-color: #fff;
  }
  
.odd {
    background-color: #f9f9f9;
}
  
.moduleName {
    flex: 1;
    font-size: 14px;
    color: #333;
}
  
.status {
    font-size: 12px;
    padding: 2px 5px;
    border-radius: 1px;
}
  
.status.passed {
    background-color: #d4edda;
    color: #155724;
}
  
.status.failed {
    background-color: #f8d7da;
    color: #721c24;
}
  
.status.expiring {
    background-color: #fff3cd;
    color: #856404;
}