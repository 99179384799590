.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modalContent {
    background: white;
    border-radius: 10px;
    width: 425px;
    height: 258px;
    padding: 20px;
    position: relative;
    text-align: center;
    line-height: 16.94px;
    font-family: "inter", sans-serif !important;

  }
  
  .closeButton {
    width: 8.85px;
    height: 8.68px;
    position: absolute;
    top: 8px;
    left: 387px;
    background: none;
    border: none;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    color: #CECECE;

  }
  
  .modalTitle {
    width: 295px;
    height: 29px;
    margin-top: 15px;
    margin-bottom: 40px;
  margin-left: 45px;
    font-size: 24px;
    font-weight: 300;
    line-height: 29.05px;
    text-align: center;
    color: #007E8D;
  }
  
  .modalDescription {
    width: 322.29px;
    height: 49.35px;
    font-size: 13px;
    font-weight: 300;
    margin-top: -15px;
    margin-left: 30px;
    color: #000000;

  }
  
  .buttonGroup {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
  }
  
  .cancelButton {
    width: 104.48px;
    height: 42.42px;
    margin-top: 15px;
    margin-left: 20px;
    background: #DFDFDF;
    color: #000000;
    border: none;
    border-radius: 40px;
    padding: 12px 20px 16px 20px;
    cursor: pointer;
    transition: background 0.3s;
    font-weight: 300;
    font-size: 14px;
    text-align: center;
  }
  
  .cancelButton:hover {
    background: #ffffff;
  }
  
  .confirmButton {
    width: 247.03px;
    height: 42.42px;
    margin-top: 15px;
    background: #007E8D;
    color: #ffffff;
    border: none;
    border-radius: 40px;
    cursor: pointer;
    transition: background 0.3s;
    font-weight: 300;
    font-size: 14px;
    text-align: center;

  }
  
  .confirmButton:hover {
    background: #007E8D;
  }
  