.e_learning_wrapper{
    width: auto;
}

.e_learning_italics{
    font-style: italic;
}

@media screen and (max-width: 970px) {
    .e_learning_wrapper>div:nth-child(3){
        flex-direction: column;
    }
}