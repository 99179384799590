.container {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-content: baseline;
  gap: 40px;
}

.column {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  flex-grow: 1;
}

.column2{
  flex-shrink: 10;
}

.row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  gap: 30px;
  max-width: 540px;
}

.abbreviation {
  width: 60px !important;
}

.customButton {
  border-radius: 5px;
}

.customButton:hover {
  background-color: rgb(170, 155, 115);
}

.descriptionList {
  margin: 30px 0px;
}

.eLearning > div {
  padding: 0px !important;
}

.instructions {
  max-width: 400px;
}

.quillContainer {
  display: block;
  max-width: 540px;
  height: 200px;
  margin-bottom: 40px;
}

.quillContainer > div > div > div {
  height: 175px;
}

.quillContainer strong {
  font-style: bold !important;
  color: rgb(61, 61, 61) !important;
  font-size: 14px;
}

.submission {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
  gap: 30px;
  max-width: 540px;
}

.verticalRule {
  border-left: 1px solid #cccccc;
  height: 400px;
  width: 1px;
}

@media screen and (max-width: 800px) {
  .row {
    flex-flow: row wrap;
    min-width: 250px;
  }

  .submission {
    min-width: 250px;
  }

  .verticalRule {
    display: none;
  }
}

h4 {
  font-size: 20px;
}

@media screen and (max-width: 415px) {
  .quillContainer {
    height: 250px;
  }
}

h4::after {
  content: "";
  display: block;
  margin: 15px 0 40px;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

strong {
  color: rgb(0, 126, 141) !important;
  font-size: 20px;
}

input {
  margin-top: 1px !important;
  height: 41.4px !important;
}

textarea {
  height: 150px !important;
}

label {
  margin-top: 10px;
}

ul {
  padding: 0px;
}

.error{
  color: #cc0000;
}

.warningTitle{
  color: #cc0000;
  width: 100%;
  text-align: center;
  font-size: 16px;
}

.warningList{
  margin: 40px 10px;
}

.warningList li{
  list-style: disc;
}

.warningButtons{
  margin-top: 20px;
  display: flex;
  gap: 20px;
}