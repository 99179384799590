.assignmentTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.title {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.table {
  width: 100%;
}

.header {
  display: flex;
  background-color: #f9f9f9;
  padding: 10px;
}

.headerCell {
  font-weight: bold;
}

.assignmentItem {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.assignmentItem:nth-child(even) {
  background-color: #f9f9f9;
}

.cell {
  padding-right: 10px;
}
