/* Adjust as needed. Right now will expand to full width of parent container */
.notice_container{
    width: 100%;
}

.notice_bar{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.notice_bar>p{
    margin: 0;
    padding: 3px 0px;
}

.requirement_color{
    background-color: #F14123;
}

.expiration_color{
    background-color: #F08A24;
}

.system_color{
    background-color: #007E8D;
}

.notice_list{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.notice_link{
    color: #007e8d;
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.notice_link:hover{
    color: #aa9b73;
}

/* used to showcase for storybook */
.story_example_container{
    max-width: 500px;
    /* min-width: 600px; */
}