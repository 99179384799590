.modal_wrapper {
    width: 100% !important;
    overflow-y: scroll;
    position: relative;
}

.model_content {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.close_modal_btn {
    position: absolute;
    right: 0;
    transform: translateX(calc(-50px));
}

.submissions_wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1250px;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.student_clin {
    max-width: 850px;
}

.student_info {
    flex-grow: 1;
}

.info_photo {
    margin-bottom: 20px;
}

.stud_photo {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.clin_assignment_wrapper {
    margin-bottom: 20px;
}

.sub_header {
    font-size: 22px;
    margin-top: 20px;
}

/* clinical assignments */
.assignmentTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.table {
    width: 100%;
}

.header {
    display: flex;
    background-color: #f9f9f9;
    padding: 10px;
}

.headerCell {
    font-weight: bold;
}

.assignmentItem {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #ccc;
}

.assignmentItem:nth-child(even) {
    background-color: #f9f9f9;
}

.cell {
    padding-right: 10px;
}

/* end clinical assignments */

.key {
    display: flex;
    flex-wrap: wrap;
}

.key_item {
    display: flex;
    margin-right: 20px;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.warningTitle {
    color: #cc0000;
    width: 100%;
    text-align: center;
    font-size: 16px;
}

.warningList {
    margin: 40px 10px;
}

.warningList li {
    list-style: disc;
}

.warningButtons {
    margin-top: 20px;
    display: flex;
    gap: 20px;
}

@media screen and (max-width: 1040px) {
    .info_photo {
        display: flex;
        flex-direction: column;
    }

    .stud_photo {
        align-items: flex-start;
    }
}

.stickyHeader {
    position: sticky;
    top: 0;
    background-color: rgba(159, 186, 76, 0.8);
    opacity: 0.8;
    z-index: 1000;
    padding: 10px;
    font-weight: bold;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3{
        margin-bottom: 0px;
    }
}