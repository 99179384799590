.containerFlex {
  display: flex;
  flex-direction: column;
}

.customTable {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
  margin-top: 10px;
  border: solid 1px #DDDDDD;
}

.customTable td { 
  line-height: 1.125rem;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-right: 0.5625rem;
}

.customTableHeader {
  background-color: #f2f2f2;
}

.customTableRow {
  border-bottom: 1px solid #ddd;
}

.customTableRow:nth-child(odd) {
  background-color: #f9f9f9;
}

.customTableHeaderRow th {
  text-align: left;
  padding-top: 0.5625rem;
  padding-bottom: 0.5625rem;
  padding-right: 0.5625rem;
}


th.smallColumn {
  padding-left: 8px
}

.table_upper_row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
}

.submitButtonContainer {
  max-width: 100px;
  margin-top: 20px;
}

.pagingContainer {
  margin-top: 30px;
}

.actionContainer {
  display: inline-flex;
  gap: 20px;
  align-items: center;
  flex: 0 0 calc(50% - 10px);
  margin: 5px;
}

.descriptionContainer {
  margin-top: 20px;
}

.tableWrapper {
  overflow-x: auto;
}

.noData {
  text-align: center;
  padding: 1rem;
}

.clickable_key{
  color: #007E8D;
  cursor: pointer;
} 

.headerNamesActionContainer {
  flex: 0 0 calc(50% - 10px);
  margin: 5px;
}

.actionAndColumnNameModalContainer {
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  
}

.customWidthAndHeight{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1000px !important;
  height: auto !important;
  background-color: rgb(0, 126, 141) !important;
}

.custom_modal{
  width: 890px !important;
  height: 500px !important;
  position: relative;
}

.modal_icon{
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(20px);
}

.lower_container{
  display: flex;
  flex-direction: column;
}

.lower_header{
  margin: 10px 0px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
  /* color: #007E8D; */
}

.lower_subheader_row{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
  min-height: 40px;
}

/* items per page */
.lower_subheader_row>label{
  margin: 0;
}

.lower_subheader_item{
  display: flex;
  justify-content: center;
  align-items: center;
}

/* not needed if using dashboard video player button */
.lower_subheader_item>h4{
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  color: #007E8D;
  margin-bottom: 0;
}

.lower_subheader_icon {
  padding-left: 5px;
  margin-top: 8px;
}

.bar{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 20px;
}

.lower_subheader_coord{
  padding-top: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #007E8D;
  cursor: pointer;
}

.buttons_col{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.button_height{
  height: 40px;
}

.checkboxSelect {
  padding-left: 5px;
}

.selected {
  background-color: #B0BED9 !important;
  color: white;
}

.item_name_link{
  text-decoration: underline;
  cursor: pointer;
  color: #007E8D;
}

.selected .item_name_link{
  text-decoration: underline;
  cursor: pointer;
  color: white;
}