.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 79px;
    height: 35px;
    padding: 10px 20px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    font-family: "Inter", sans-serif !important;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    margin: 6px;
    
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .divider {
    height: 1px;
    background-color: #EDEDED;
    width: 15%;
  }
  
  .initiate {
    font-family: "Inter", sans-serif !important;
    background-color: #007E8D !important;
    color: #ffffff;
  }
  
  .sent {
    font-family: "Inter", sans-serif !important;
    background-color: #95D603 !important;
    color: #ffffff;
  }
  
  .na {
    font-family: "Inter", sans-serif !important;
    background-color: #DFDFDF !important;
    color: #ffffff;
    cursor: not-allowed;
  }
  
  .default {
    background-color: #f0f0f0;
    color: black;
  }
  
  .columnGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  