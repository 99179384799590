.dashboardContactInformationModal {

    .flexContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .flexItem {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        box-sizing: border-box;

    }

    .header {
        width: 90%;
        justify-content: left;
    }

    .close {
        width: 10%;
        justify-content: right;
        align-items: flex-start;
        color: #AAAAAA;
        cursor: pointer;
        font-size: 2.0rem;
        font-weight: bold;
        line-height: 1;
    }


    .info {
        justify-content: left;
        width: 60%;
    }

    .info ul {
        padding: 20px;
    }

    .info li {
        line-height: 1.7rem;
    }

    ul.no-bullet,
    ol.no-bullet {
        list-style: none;
        padding-left: 0;
    }

    .image {
        justify-content: center;
        width: 40%;
    }
}

.customWidthAndHeight {
    width: 700px !important;
}

