.container {
  display: flex;
  flex-direction: column;
}

.nav {
  min-width: 220px;
}

.banner {
  width: 80%;
  margin: 0 auto;
}

.contentContainer {
  /* max-width: 100%; */
  max-width: 95rem;
  width: 100%;
  margin: 10px auto 20px auto;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
}

.contentNav {
  flex: 0 1 250px;
}

.children {
  flex: 1;
  overflow-x: hidden;
}

.contentNavItem {
  padding: 5px 10px;
}

@media screen and (max-width: 1000px) {
  .banner {
    width: 100%;
  }
}

@media screen and (max-width: 1575px) {
  .contentContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 1175px) {
  .contentContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 770px) {
  .contentContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 680px){
  .contentContainer{
    flex-direction: column;
  }
  .contentNav{
    flex: none;
    min-width: 250px;
  }
}