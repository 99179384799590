.dashboardAffiliationRequestModal {

    .flexContainer {
        display: flex;
        flex-wrap: wrap;
    }

    .flexItem {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        box-sizing: border-box;

    }

    .errorContainer ul {
        margin-top: 0px;
    }

    .errorContainer ul li {
        list-style-type: inherit;
        color: red;
    }

    h1::after {
        display: none;
    }

    .header {
        width: 90%;
        justify-content: left;
        margin-bottom: 30px;
    }

    .help {
        justify-content: left;
        margin-bottom: 20px;
    }

    .close {
        width: 10%;
        justify-content: right;
        align-items: flex-start;
        color: #AAAAAA;
        cursor: pointer;
        font-size: 2.0rem;
        font-weight: bold;
        line-height: 1;
    }

    .row {
        justify-content: left;
        width: 100%;
    }

    .row label {
        display: unset !important;
    }

    .row {
        margin-bottom: 20px;
    }

    button {
        margin-right: 10px;
        font-size: 11px;
        background-color: #008CBA;
    }

    button:hover,
    button:focus,
    .button:hover,
    .button:focus {
        background-color: #007095;
    }

    button.disabled,
    button[disabled],
    .button.disabled,
    .button[disabled] {
        background-color: #008CBA;
        border-color: #007095;
        color: #FFFFFF;
        cursor: default;
        opacity: 0.7;
        box-shadow: none;
    }
}