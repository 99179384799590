.summary_key_modal{
    .modal_container{
        display: flex;
        flex-direction: column;
    }

    .modal_container_row{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        width: 100%;
    }

    .modal_container_row li {
        flex: 0 0 calc(33.33%); /* 33 percent for width (3 columns) */
        text-wrap: wrap;
        padding: 0px 5px;
    }

    .modal_container_header_row{
        display: flex;
        flex-direction: row;
    }

    .key_abbrev{
        color: #007e8d;
        font-weight: bold;
    }

    .just_between{
        justify-content: space-between;
    }
}

.customWidthAndHeight{
    width: 1000px !important;
}

@media screen and (max-width: 1050px) {
    .customWidthAndHeight{
        width: 800px !important;
    }
}

@media screen and (max-width: 850px) {
    .customWidthAndHeight{
        width: 600px !important;
    }
}

@media screen and (max-width: 650px) {
    .summary_key_modal{
        .modal_container_row{
            flex-direction: column;
        }
        .modal_container_col{
            width: 100%;
        }
    }
    .customWidthAndHeight{
        width: 400px !important;
    }
}

/* This doesnt work because there is a min-width on the modal. Smaller screen sizes such as phones wont be able to use this modal correctly */
/* @media screen and (max-width: 450px) {
    .customWidthAndHeight{
        width: 280px !important;
    }
} */