.container {
  display: flex;
  flex-direction: column;
  max-width: 1150px;
  /* Matches max width of list items */
  /* min-width: 600px; */
  /* Prevents extreme shrinking */
  width: 100%;
  height: auto;
  background-color: #FFFFFF;
  border-radius: 4px;
  margin: 0 auto;
  overflow-x: auto;
}

.scrollWrapper {
  width: 100%;
  overflow-x: auto;
  /* Enables horizontal scrolling */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.listContainer {
  flex-grow: 1;
}
