.dashboardMyDemographics {
  display: flex;
  justify-content: left;
  flex-direction: column;

  .containerImage {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;


    img {
      width: 133px !important;
      height: 133px !important;
      margin-top: 0px !important;
      margin-bottom: 10px !important;
    }

    button {
      width: 133px !important;
    }
  }

  .photo_loader{
    /* TODO -- Adjust me to fit loader on top of image placeholder while data fetches */
    position: absolute;
    left: 35px;
    top: 35px;
    color: #007e8d;
  }

  .loader{
    height: 100px;
    padding-top: 10px;
    color: #007e8d;
  }

  hr {
    border: solid #DDDDDD;
    border-width: 1px 0 0;
    clear: both;
    height: 0;
    margin: 50px 0px;
  }

  h3 {
    font-size: 20px;
  }

  .saveButton {
    background: #007e8d;
    color: #ffffff;
    font-weight: 300;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
  }

  .hover:hover{
    background-color: #aa9b73;
  }
  /* .saveButton:hover {
    background-color: #aa9b73;
  } */
}