   button.previewLink {
     background-color: transparent;
     color: #007e8d;
     text-decoration: none;
     outline: none;
     border: none;

     .previewIcon {
       color: #007e8d !important;
       margin: 0 5px;
     }
   }

   button.previewLink:hover {
     color: #aa9b73;
   }