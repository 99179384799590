.passportDownloadItem {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: transparent;
  color: #007e8d;
  border-color: transparent;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  cursor: pointer;

  svg {
    width: 15px;
    height: auto;
    vertical-align: middle;
    margin-right: 5px;
    padding-bottom: 5px;
    color: #007e8d;
  }

  span {
    color: #222222;
  }
}