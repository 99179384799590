.modal {
    width: 60%;
}

.modalContent {
    position: relative;
    font-weight: 300;
}

.modalHeader {
    font-size: 20px;
}

.modalInfo, .input {
    margin-bottom: 30px;
}

.input {
    font-family: inherit;
    font-size: 13px ;
}

.modalHeaderContainer {
    margin-bottom: 30px;
}

.modalCloseBtn {
    position: absolute;
    color: #AAAAAA;
    cursor: pointer;
    font-size: 2rem;
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;
    top: -1.375rem;
    right: -0.625rem;
}