.usersTableContainer {
  display: flex;
  flex-direction: column;

  .customTable {
    width: 100%;
    border-collapse: collapse;
    font-size: 12px;
    margin-top: 10px;
    border: solid 1px #DDDDDD;
  }

  .customTable td {
    line-height: 1.125rem;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
    padding-right: 0.5625rem;
  }

  .customTableHeader {
    background-color: #f2f2f2;
  }

  .customTableRow {
    /* border-bottom: 1px solid #ddd; */
    background-color: transparent !important;
  }

  /* .customTableRow:nth-child(odd) {
  background-color: #f9f9f9;
} */

  .customTableRow>td {
    padding-left: 10px;
  }

  .customTableRow>td>div>input {
    height: 20px !important;
    margin-top: 5px !important;
  }

  .customTableHeaderRow th {
    text-align: left;
    padding-top: 0.5625rem;
    padding-bottom: 0.5625rem;
    padding-right: 0.5625rem;
    padding-left: 10px;
  }

  th>div:first-child {
    padding-right: 0px !important;
  }

  th>div:first-child>div {
    margin-left: 10px !important;
  }

  th.smallColumn {
    /* padding-left: 8px */
  }

  th.smallColumn>div>div {
    /* margin-left: 5px; */
  }

  .table_upper_row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    /* padding-right: 15px; */
  }

  .table_upper_row>div:first-child {
    margin: 0px !important;
  }

  .table_upper_row>div:last-child {
    padding: 0px !important;
  }

  .table_upper_row>div:last-child input {
    margin: 0px !important;
  }

  .submitButtonContainer {
    max-width: 100px;
    margin-top: 20px;

    button {
      background: #007e8d;
      color: #ffffff;
      font-weight: 300;
      font-family: "Roboto", sans-serif;
      text-transform: uppercase;
      padding: 0px 20px;
      font-size: 14px;
      letter-spacing: 1px;
      transition: background-color 300ms ease-out;
      height: 37px;
    }
  }

  .hover>button:hover {
    background-color: #aa9b73;
  }

  .pagingContainer {
    margin-top: 20px;
  }

  .actionContainer {
    display: inline-flex;
    gap: 20px;
    align-items: center;
  }

  .descriptionContainer {
    margin: 20px 0px;
  }

  .tableContainer{
    position: relative;
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .noScroll{
    overflow: visible; 
  }

  .noData {
    text-align: center;
    padding: 1rem;
  }

  .even {
    background-color: #f9f9f9 !important;
  }

  .loadingContainer {
    text-align: center;
  }

  .loadingContainer:first-child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background */
    z-index: 1000; /* Ensure it's above other content */
  }
}