.pageContent{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.pageContent a{
  flex: 1 0 300px;
  min-width: 200px;
  max-width: 300px;
  margin-right: 10px;
  word-wrap: break-word;
}