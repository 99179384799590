.dropdown .header {
    font-size: 1rem;
    font-weight: 300;
    color: #007E8D;
    padding: 5px 10px;
}

.dropdown ul {
    margin-left: 20px;
    padding: 15px;
}