.label {
    font-weight: bold;
}

p {
    margin: 0px !important;
}

.unset {
    ol{
        list-style: decimal;

        li{
            list-style-type: unset;
        }
    }

    ul{
        list-style: unset;

        li{
            list-style-type: unset;
        }
    }
}

