
.warningTitle{
    color: #cc0000;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  
  .warningList{
    margin: 40px 10px;
  }
  
  .warningList li{
    list-style: disc;
  }
  
  .warningButtons{
    margin-top: 20px;
    display: flex;
    gap: 20px;
  }

  .customButton {
    border-radius: 5px;
  }
  
  .customButton:hover {
    background-color: rgb(170, 155, 115);
  }

  .center{
    display: flex;
    align-items: center;
    justify-content: center;
  }