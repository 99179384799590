.fileNameUploadDateCol {
  display: flex;
  align-items: center;
  text-align: left;
  width: 100%;
  padding-right: 10px; /* Adjust padding as needed */
}

.fileNameUploadDateCol>label{
  margin-top: 4px;
}

.uploadDateCol {
  text-align: left;
  width: 30%;
  padding-right: 60px; /* Adjust padding as needed */
}

.removeCol {
  text-align: center;
  width: 10%;
}

.checkbox {
  margin-right: 10px; /* Adjust space between checkbox and file name as needed */
}

.removeButton {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 18px;
  color: red;
}

.label{
  background-color: aquamarine;
  margin: 0;
}