.results_row{
    max-width: 1000px;
    display: flex;
    /* align-items: center; */
    font-size: 12px;
}

.subject_col{
    display: flex;
    align-items: center;
    padding: 9px 10px;
    max-width: 333px;
    min-width: 100px;
    flex-grow: 1;
    flex-basis: 0
}

.status_col{
    display: flex;
    align-items: center;
    padding: 9px 10px;
    max-width: 333px;
    min-width: 100px;
    flex-grow: 1;
    flex-basis: 0;
}

.date_col{
    display: flex;
    align-items: center;
    padding: 9px 10px;
    max-width: 334px;
    min-width: 100px;
    flex-grow: 1;
    flex-basis: 0;
}

@media screen and (max-width: 464px){
    .subject_col{
        max-width: 434px;
    }
    .status_col{
        max-width: 65px;
    }
    .date_col{
        max-width: 150px;
    }
}