.requirementTableContainer {
  max-width: 100%;
  overflow-x: auto;
  font-size: 12px;


  .requirementTableHeader {
    display: flex;
    flex-direction: row;
  }

  .requirementTableHeaderCol {
    display: flex;
    flex-direction: row;

    font-weight: bold;
    height: 50px;
    background-color: #ebebeb;
  }

  .requirementTableHeaderCol>div>div {
    margin: 0;
  }

  .requirementTableHeaderCol>div {
    padding: 10px;
  }

  .rowItem {
    display: flex;
    width: 100%;
  }

  .requirementTableContentEven>div>div {
    background-color: #f3f3f3;
  }

  .requirementTableHeaderCol span {
    padding: 10px;
    display: flex;
    align-items: center;
  }

  .textCenter {
    justify-content: center;
    text-align: center;
  }

  .button {
    background-color: #007e8d;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 300ms ease-out;
    color: #ffffff;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    padding: 0px 20px;
    font-size: 14px;
    letter-spacing: 1px;
    margin: 30px 0px 20px 0px;
    height: 36px;
  }

  .button:hover {
    background: #aa9b73;
  }

  .requirementNoDataCol{
    width: 100%;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.requirementNoDataCol>p{
    margin: 0;
}
}

.requirementTableContainer.isLoading {
  overflow-y: hidden;
}

.pagingContainer {
  margin-top: 30px;
  max-width: 910px;
  line-height: 1.7;
  font-size: 14px;
  width: 100%;
  font-weight: 300;
  letter-spacing: 1px;
  color: #3d3d3d;
}

.table_upper_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 910px;
}