.container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    background-color: #f0f0f0;
}

.scrollContainer {
    width: auto;
    height: 50px;
    background-color: white;
    border: 1px solid #ccc;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
}

.content {
    display: flex;
    align-items: center;
    min-height: 50px;
    line-height: 50px;
    font-size: 12px;
    height: 100%;
    margin-right: 5px;
}

.columnCheckBox {
    width: 4.12%;
    min-width: 40px;
    text-align: center;
}

.columnIndex0 {
    width: 15.11%;
    min-width: 150px;
}

.columnIndex1 {
    width: 9.11%;
    min-width: 90px;
}

.columnIndex2 {
    width: 9.11%;
    min-width: 90px;
}

.columnIndex3 {
    width: 9.11%;
    min-width: 90px;
}

.columnIndex4 {
    width: 15.11%;
    min-width: 150px;
}

.columnIndex5 {
    width: 12.11%;
    min-width: 120px;
}

.columnIndex6 {
    width: 12.11%;
    min-width: 120px;
}

.columnIndex7 {
    width: 8.11%;
    min-width: 80px;
}

.columnIndex0, .columnIndex1, .columnIndex2, .columnIndex3, .columnIndex4, .columnIndex5, .columnIndex6, .columnIndex7 {
    margin-right: 0px;
    padding-left: 10px;
}

.row {
    color: #222222;
    display: flex;
    align-items: center;
}

.row input,
.row select {
    margin: 0px !important;
}

.row label {
    display: none;
}

.evenRow {
    background-color: #F9F9F9;
    min-width: 100%;
    display: flex;
}

.evenRow .columnIndex0,
.evenRow .columnIndex1,
.evenRow .columnIndex2,
.evenRow .columnIndex3,
.evenRow .columnIndex4,
.evenRow .columnIndex5,
.evenRow .columnIndex6,
.evenRow .columnIndex7 {
    background-color: #F9F9F9;
}

.selectedRow {
    color: #FFFFFF;
    background-color: #008cba;
}

.selectedRow a {
    color: #FFFFFF;
    text-decoration: "underline";
}

.selectedRow:hover {
    background-color: #0087b3;
}

.selectedRow .columnIndex0,
.selectedRow .columnIndex1,
.selectedRow .columnIndex2,
.selectedRow .columnIndex3,
.selectedRow .columnIndex4,
.selectedRow .columnIndex5,
.selectedRow .columnIndex6,
.selectedRow .columnIndex7 {
    background-color: #008cba;
}

.selectedRow:hover .columnIndex0,
.selectedRow:hover .columnIndex1,
.selectedRow:hover .columnIndex2,
.selectedRow:hover .columnIndex3,
.selectedRow:hover .columnIndex4,
.selectedRow:hover .columnIndex5,
.selectedRow:hover .columnIndex6,
.selectedRow:hover .columnIndex7 {
    background-color: #0087b3;
}

.unSelectIcon {
    content: ' ';
    border-radius: 3px;
    color: #FFFFFF !important;
    width: 100%;
}

.selectedIcon {
    color: #FFFFFF !important;
}

.preloader {
    width: 100%;
    height: 100%;
}

.customModal {
    width: 600px;
    height: 400px;
}

.assignmentAction {
    color: #007095;
}

.assignmentAdd {
    margin: 0;
}

.assignmentEdit {
    margin: 0 10px;
}

.assignmentDelete {
    margin: 0px;
}

.assignmentSave {
    margin: 0px;
}

.assignmentCancel {
    margin: 0 10px;
}

.customCheckedIcon {
    color: #FFFFFF !important;
    background-color: transparent;
    border-radius: 3px;
    height: 17px;
}

.customUncheckedIcon {
    color: transparent !important;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 3px;
    height: 15px;
}

@media (max-width: 767px) {
    .column {
        flex-basis: 100%;
    }
}