.student_info {
    max-width: 850px;
    margin-bottom: 10px;
}

.header {
    margin-bottom: 10px;
}

.info_row {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 15px;
    margin-bottom: 10px;
}

.info_item {
    display: flex;
    flex-direction: column;
    min-height: 50px;
}

.info_item > p {
    margin-bottom: 0;
}

.info_data {
    color: #007e8d;
}

.elip_data{
    color: #007e8d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltip_container{
    position: relative;
}

.hidden{
    display: none;
}

.active {
    display: flex;
    position: absolute;
    z-index: 1000;
    transform: translateY(50px);
    word-break: break-all;
    background-color: #007e8d;
    color: white;
    width: 100%;
    padding: 3px 6px;
    border-radius: 5px;
}

/* .stickyHeader {
    position: sticky;
    top: 0;
    background-color: rgba(159, 186, 76, 0.8);
    opacity: 0.8;
    z-index: 1000;
    padding: 10px;
    font-weight: bold;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    h3{
        margin-bottom: 0px;
    }
} */