.container h1 {
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
}

.container h1::after {
  content: "";
  display: block;
  height: 1px;
  width: 132px;
  margin: 15px 0 40px 0;
  background: #007e8d;
}
