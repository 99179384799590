.mainContainer {
  width: 100%;

  h1::after {
    height: unset !important;
  }

  strong {
    font-weight: bold;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
  }

  .filterTopContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-sizing: border-box;


    .buttons,
    .inputSearch {
      display: flex;
      align-items: center;
    }

    .buttons button {
      margin: 0 5px;
    }

    .inputSearch div {
      padding: unset !important;
    }

    .itemsPerPage {
      min-width: 200px;
    }

    .itemsPerPage select {
      width: 150px !important;
    }

    .itemsPerPageSelectBox select {
      width: 150px !important;
    }

    button {
      background: #008CBA;
      color: #ffffff;
      padding: 8px 20px;
      font-size: 11px;
      font-family: "Source Sans Pro", sans-serif;
      letter-spacing: 1px;
      transition: background-color 300ms ease-out;
    }

    button:hover {
      background: #007095;
    }
  }

  .tableContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0;
    background-color: #f0f0f0;
    width: 100%;
    border: 1px solid #DDD;
  }

  .scrollContainer {
    width: auto;
    height: 100%;
    background-color: white;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }

  .headerContainer {
    display: flex;
    border-bottom: 1px solid #DDD;
    background-color: #F5F5F5;
  }

  .content {
    display: inline-block;
    height: 50px;
    line-height: 50px;
    font-size: 12px;
    height: 100%;
    margin-right: 5px;
  }

  .textCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .columnCheckBox {
    width: 4.12%;
    min-width: 40px;
    text-align: center;
  }

  .columnIndex0 {
    width: 9.11%;
    min-width: 90px;
  }

  .columnIndex1 {
    width: 20.11%;
    min-width: 200px;
  }

  .columnIndex2 {
    width: 9.11%;
    min-width: 90px;
  }

  .columnIndex3 {
    width: 9.11%;
    min-width: 90px;
  }

  .columnIndex4 {
    width: 8.11%;
    min-width: 80px;
  }

  .columnIndex5 {
    width: 12.11%;
    min-width: 120px;
  }

  .columnIndex6 {
    width: 17.11%;
    min-width: 170px;
  }

  .columnIndex7 {
    width: 10.11%;
    min-width: 100px;
  }

  .columnIndex0,
  .columnIndex1,
  .columnIndex2,
  .columnIndex3,
  .columnIndex4,
  .columnIndex5,
  .columnIndex6,
  .columnIndex7 {
    margin-right: 0px;
    padding-left: 5px;
    font-weight: bold;
  }

  .footerContainer {
    border-top: 1px solid #DDD;
    background-color: #F5F5F5;

    select {
      margin: 10px 0px 10px 0px !important;
    }
  }

  .paginationContainer {
    padding-top: 25px;
  }

  .inlineList {
    margin: 0 auto 1.0625rem auto;
    margin-right: 0;
    padding: 0;
    list-style: none;
    overflow: hidden;
    display: flex;
    list-style-type: none;
  }

  .inlineList li {
    width: fit-content;
    margin-right: 40px;
  }

  .inlineList>li>* {
    display: block;
  }

  .header:first-child {
    padding-right: 10px !important;
  }


  @media (max-width: 767px) {
    .column {
      flex-basis: 100%;
    }
  }
}