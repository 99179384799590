.select_container{
    position: relative;
    display: flex;
    max-width: 350px;
}

.select{
    padding: 8px;
    /* margin-bottom: 10px; */
    width: 100%;

    border: 1px solid #cccccc;
    /* border-bottom: none; */

    appearance: none;
    background-image: url("../../images/select.svg");
    background-repeat: no-repeat;
    background-position: right 20px top 50%;
    padding-right: 40px;

    cursor: pointer;
}

.select_active{
    color: #007e8d;
    border: 2px solid black;
}

.select:hover{
    color: #007e8d;
    border: 1px solid black;
    /* border-bottom: none; */
}

.dropdown{
    background-color: white;
    /* active state for dropdown */
    position: absolute;
    top: 100%;
    z-index: 1000;
    border: 1px solid #cccccc;
    /* attempt at matching default dropshadow for select box */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-height: 200px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
}

.option{
    display: flex;
    align-items: center;
    color: #007e8d;
    padding: 0px 10px 0px 10px;
    cursor: pointer;
    /* Prevent text from wrapping */
    white-space: nowrap;
}

/* targets the checkbox */
.option input{
    margin-right: 10px;
}

.option:hover{
    background-color: #818181;
    color: white;
}

.tip_container{
    margin-top: 5px;
}

.custom_tip{
    max-width: 300px;
    padding: 5px;
    font-size: 0.65rem !important;
    animation: animateTool 0.5s;
}

@keyframes animateTool{
    0% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}

.select {
    max-width: 100%;
    /* Ensures the width can adapt to the container */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* Optional: to see the div's boundary */
    padding-right: 50px;
    /* Optional: to add some padding inside the div */
}

.dropdown {
    background-color: white;
}