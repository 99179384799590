.mySecuritySettings .itemCenter {
  text-align: center;
  margin-bottom: 1em;
}

.mySecuritySettings a,
.mySecuritySettings a:visited {
  color: #007e8d;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.mySecuritySettings a:hover {
  color: #aa9b73;
}

.mySecuritySettings p {
  margin: 0 0 30px;
}


.mySecuritySettings .row {
  display: flex;
  flex-wrap: wrap;
}

.mySecuritySettings .column {
  flex: 1;
  padding-left: 0rem;
  padding-right: 1.5rem;
}

.mySecuritySettings .column6 {
  flex: 0 0 55%;
}

.mySecuritySettings .column4 {
  flex: 0 0 45%;
}

.mySecuritySettings button:hover {
  background-color: #aa9b73;
}

.mySecuritySettings button {
  margin-top: 20px !important;
}

.mySecuritySettings .error {
  font-style: normal;
  background: none;
  color: #e00000;
}

.mySecuritySettings .alertBox.alert {
  background-color: #f04124;
  border-color: #de2d0f;
  color: #FFFFFF;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  display: block;
  font-weight: normal;
  margin: 1.25rem 0;
  position: relative;
  padding: 0.875rem 1.5rem 0.875rem 0.875rem;
  font-size: 0.8125rem;
  transition: opacity 300ms ease-out;
}

.mySecuritySettings .label.success {
  background-color: #43AC6A;
  color: #FFFFFF;
  border-radius: 1000px;
  font-weight: normal;
  font-family: "Source Sans Pro", sans-serif;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  margin-bottom: auto;
  padding: 0.25rem 0.5rem 0.25rem;
  font-size: 0.6875rem;
}

@media (max-width: 767px) {
  .mySecuritySettings .column {
    flex-basis: 100%;
  }
}