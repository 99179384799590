.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.info {
  flex-grow: 1;
}

.buttons {
  display: flex;
}

.button {
  cursor: pointer;
  margin-left: 10px; /* Adjust the value as needed */
}

.active {
  background-color: #007e8d;
  color: white;
  font-weight: bold;
  border-radius: 10%; /* Ensure square around active button is rounded */
  padding: 0px 10px; /* Adjust padding as needed */
}
