.learning_item_wrapper{
    height: auto;
    width: 375px;
    display: flex;
    padding: 0px 15px;

}

.learning_item_icon_col{
    height: 50px;
    width: 50px;
    margin-right: 5px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.learning_icon{
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background-color: #aa9b73;
}

.learning_item_content_col{
    width: 100%;
}

.learning_item_content_col a{
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;

    color: #007e8d;
    line-height: 1.4;
    letter-spacing: 1px;
    font-size: 20px;
}

.learning_item_content_col a:hover{
    color: #aa9b73;
}

.learning_item_content_col p{
    margin-bottom: 30px;
}

@media screen and (max-width: 425px){
    .learning_item_wrapper{
        width: 330px;
    }
}

@media screen and (max-width: 350px){
    .learning_item_wrapper{
        width: 255px;
    }
}