.site_requirements_subheader{
    font-size: 20px;
}

.site_requirements_content{
    display: flex;
    flex-direction: row;
    flex-basis: 0;
    flex-wrap: nowrap;
    gap: 30px;
    width: 100%;

    /* max-width can be removed when added to layout if layout has width constraints*/
    max-width: 1000px;
}

.site_requirements_col{
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex-grow: 1;
    width: 50%;
}

/* This is a clone of the link styles from LinkItem as it cannot be imported due to error */
.site_requirements_cpr_link{
    color: #007e8d;
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.site_requirements_cpr_link:hover{
    color: #aa9b73;
}
/* End LinkItem style clone */

@media screen and (max-width: 760px) {
    .site_requirements_content{
        flex-direction: column;
        gap: 0;
    }
    .site_requirements_col{
        width: 100%;
    }
}