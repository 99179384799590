.message_form_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 350px;
  padding-right: 5px;
  /* Adjust max height as needed */
  max-height: 350px;
}

.message_form_container > h5 {
  margin-bottom: 5px;
}

.sender_row {
  font-size: 12px;
  margin: 0px 0px 5px 0px;
}

.message_reply_container {
  display: flex;
  flex-direction: column;
}

.message_reply_container > p {
  font-size: 16px;
}

.message_reply_col {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.message_reply_buttons_row > p {
  margin: 0px 0px 2px 0px;
}

.custom_reply {
  color: #007e8d;
  cursor: pointer;
  margin-bottom: 5px;
}

.custom_reply:hover {
  color: #aa9b73;
}

.reply_area {
  display: flex;
  flex-direction: column;
}

.buttons_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 3px;
}

.custom_button {
  border: none;
  background-color: #007e8d;
  color: #ffffff;

  padding: 0 0.8em;
  font-size: 14px;
  font-weight: 300;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  cursor: pointer;
  padding: 1px 5px !important;
  border-radius: 5px;
}

.custom_delete_button {
  color: red;
  cursor: pointer;
}

.no_message_container {
  display: flex;
  flex-direction: column;
}

.no_message_container > div > p {
  font-size: 16px;
}

.text_area {
  resize: none;
  overflow-y: scroll;
  text-wrap: wrap;
  height: 150px;
  min-width: 300px;
}

.errors_list {
  padding: 0;
  margin: 5px 0px 0px 0px;
}

.errors_list > li {
  color: red;
}
