.dashboardAssignmentsPage {
  width: 100%;

  .row {
    display: flex;
    align-items: center;
  }

  .column {
    display: flex;
    align-items: center;
    margin-right: 10px;
    word-break: break-all;
  }

  p {
    margin: 30px 0;
  }

  hr {
    border: solid #DDDDDD;
    border-width: 1px 0 0;
    clear: both;
    margin: 50px 0;
    height: 0;
  }


}