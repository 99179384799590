.passport_container{
    display: flex;
}

.passport_col{
    display: flex;
    flex-direction: column;
    width: 50%;
}

.passport_col_left{
    padding-right: 20px;
}

.passport_col_right{
    padding-left: 20px;
}

.passport_col_mid{
    width: 75%;
}

.passport_row_mid{
    justify-content: center;
}

.passport_col_subheader{
    font-size: 18px;
    margin-bottom: 30px;
}

.link_container{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    margin-left: 15px;
}

.passport_link_item{
    color: #007e8d;
    text-decoration: none;
    outline: none;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

.passport_link_item:hover{
    color: #aa9b73;
}

@media screen and (max-width: 740px) {
    .passport_container{
        flex-direction: column;
    }

    .passport_col{
        width: 100%;
    }

    .passport_col_left,
    .passport_col_right{
        padding: 0px;
    }
}