
.flexContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.flexItem {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    box-sizing: border-box;

}

.close {
    width: 10%;
    justify-content: right;
    align-items: flex-start;
    color: #AAAAAA;
    cursor: pointer;
    font-size: 2.0rem;
    font-weight: bold;
    line-height: 1;
}
.customWidthAndHeight {
  width: 80% !important;
}

.row {
    display: flex;
}