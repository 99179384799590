.dash_search_row{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: fit-content;

    padding: 5px;
}

.dash_search_label{
    padding-right: 10px;
    padding-bottom: 16px;
    color: #3d3d3d;
    font-weight: 300;
    font-size: 14px;
}

.dash_search_label:hover{
    cursor: pointer;
}

