.modal_wrapper{
    width: 60% !important;
    max-width: none !important;
    overflow-y: scroll;
    position: relative;
}

.model_content{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.close_modal_btn{
    position: absolute;
    right: 0;
    transform: translateX(calc(-50px));
}