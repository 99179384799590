.image_container {
    display: flex;
    flex-direction: column;
    /* align-items: center;  */
    max-width: 150px;
    /* margin: auto;  */
    padding: 0;
}

.container {
    display: flex;
    flex-direction: column;
    max-width: 175px;
    height: fit-content;
    align-items: center;
}


.heading {
    color: #6f6f6f;
    font-size: 20px;
    font-family: "Roboto", sans-serif;
}