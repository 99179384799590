.table {
  border-spacing: 0;
  width: 100%;

  border: solid 1px #dddddd;
  min-width: 400px;
  overflow-x: scroll;
  position: relative;
}

.table_wrapper{
  display: flex;
  overflow-x: scroll;
}

.thStyle {
  padding: 0.5625rem 0.625rem;
  border-bottom: 1px solid #dddddd;
  font-weight: bold;
  text-align: left;
}
tr:nth-child(even) {
  background-color: #f9f9f9;
}
.up {
  background-image: url("./images/up_arrow.png");
}
.down {
  background-image: url("./images/down_arrow.png");
}
.default {
  background-image: url("./images/default.png");
}
th.up,
th.default,
th.down {
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center right;
}

.theadContent {
  background-color: #f5f5f5;
}

.tutorialHeaderText {
  font-weight: bold;
  text-decoration: underline;
}
.tutorialItem {
  display: flex;
  align-items: center;
  color: #007e8d;
}
.healthcareGuideText {
  color: #007e8d;
  text-decoration: none;
}
.tableOptionContainer {
  /* width: 760px;  */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.tableOptionContainer>div:nth-child(3)>div>input{
  margin-bottom: 0;
}
.tableOptionContainer>div:nth-child(1)>div{
  margin-top: 0;
}
.itemsPerPageContent {
  display: flex;
  align-items: center;
}

.buttonContent {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
}
.buttonContent .button {
  margin-top: 5px;
  background-color: #008cba;
  margin-right: 10px;
  transition: background-color 300ms ease-out;
}
.button:hover:enabled,
.button:focus:enabled {
  background-color: #007095;
}

.searchContent {
  display: flex;
  align-items: center;
}
.paginationBar {
  display: flex;
  justify-content: end;
  color: #999999;
  margin-top: 1rem;
}
.paginationBar > div {
  padding: 0px 0.75rem;
}
.paginationButton {
  background: #008cba;
  border-radius: 3px;
  cursor: default;
}

.paginationButton > a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}

.selectItems{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.selectItem{
  flex-grow: 1;
  margin-right: 20px;
}
.table_key{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.table_key>div{
  width: 100%;
}
.table_key>div>ul{
  display: flex;
  flex-wrap: wrap;
  min-width: 115px;
}
.table_key>div>ul>li{
  width: auto;
  margin-right: 20px;
}
.modalBox {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #007e8d !important;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  position: relative;
  max-width: 1150px !important;
  max-height: 695px !important;
  width: 1150px !important;
  height: 695px !important;
}
.customModal {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 20px;
}
.modal_row {
  position: absolute;
  top: 30px;
  right: 30px;
}
.videoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.videoWrapper iframe {
  width: 100%;
  height: 100%;
  border: none;
}

@media screen and (max-width: 1180px) {
  .modalBox{
    max-width: 900px !important;
    max-height: 570px !important;
  }
  .customModal {
    max-width: 1000px !important;
    max-height: 490px !important;
  }
}

@media screen and (max-width: 935px) {
  .modalBox{
    max-width: 700px !important;
    max-height: 440px !important;
  }
}

@media screen and (max-width: 725px){
  .modalBox{
    max-width: 500px !important;
    max-height: 325px !important;
  }
}

@media screen and (max-width: 530px){
  .modalBox{
    max-width: 280px !important;
    max-height: 260px !important;
  }
  .customModal{
    padding: 10px !important;
  }
}
/* Modal in shared-ui cannot go below this */