.modal_wrapper {
    width: auto !important;
    max-width: none !important;
    overflow-y: scroll;
    position: relative;
}

.model_content {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.close_modal_btn {
    position: absolute;
    right: 0;
    transform: translateX(calc(-50px));
}

.fake_link {
    color: #007e8d;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
}

.fake_link:hover {
    color: #aa9b73;
}

.record_row {
    display: flex;
    flex-direction: row;

    min-height: 50px;
    width: 100%;
}

.record_item {
    display: flex;
    align-items: center;
    min-width: 200px;
    width: 200px;

    padding: 0px 10px 0px 10px;
}

.req_name,
.category {
    min-width: 300px;
    width: 300px;
}

.icon {
    display: flex;
    justify-content: center;
    min-width: 150px;
    width: 150px;
}

.content {
    margin-bottom: 0px;
}

.red {
    color: red;
}

.dueDate {
    color: #cc0000;
}