.page {
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 30px;
}

.header {
  align-self: flex-start;
}

.pageContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.steps {
  padding: 0px 15px;
}

.steps>div>li:first-child>div:nth-child(2)>span>span{
  color: black;
  font-weight: bold;
}

.updated_notices{
  display: flex;
  flex-direction: column;
  min-width: 250px;

  padding-left: 20px;
  border-left: 1px solid #dddddd;
}

.header_badge_description_container{
  display: flex;
  flex-direction: column;
}

.header_badge_row{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  /* margin-bottom: 10px; */
}

.header_badge_description_container>div>p,
.header_badge_row>p{
  margin-bottom: 0;
}

.requirement_notices,
.expiration_notices,
.system_notices{
  margin-bottom: 20px;
}

.notices {
  min-width: 250px;
  max-width: 250px;
  padding-left: 30px;
  border-left: 1px solid #dddddd;
  margin-left: 45px;
  margin-right: 15px;
}

h3 {
  font-size: 20px;
  margin-bottom: 30px;
}

.modal_wrapper{
  width: auto !important;
  max-width: none !important;
  overflow-y: scroll;
  position: relative;
}

.model_content{
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.close_modal_btn{
  position: absolute;
  right: 0;
  transform: translateX(calc(-50px));
}

@media screen and (max-width: 850px) {
  .pageContent{
    flex-direction: column;
  }

  .updated_notices{
    padding-left: 0;
    border-left: none;

    padding-top: 20px;
    border-top: 1px solid #dddddd;
  }
}