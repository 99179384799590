.container {
  display: flex;
  flex-direction: column;
  padding: 90px 0;
  font-family: "Roboto", sans-serif;
}

.container h1 {
  font-size: 35px;
  color: #007e8d;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  margin: 0;
}

.container h1::after {
  content: "";
  display: block;
  height: 1px;
  width: 132px;
  margin: 15px 0 40px 0;
  background: #007e8d;
}

.container h2 {
  font-size: 30px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
  margin-bottom: 30px;
}

.container h3 {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
  margin-bottom: 30px;
}

.container h4 {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222222;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  margin: 0;
}
.container h5 {
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #222222;
  line-height: 1.4;
  text-rendering: optimizeLegibility;
  margin: 0;
}

.container h5 {
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}

.container p {
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
  letter-spacing: 1px;
  color: #3d3d3d;
  line-height: 1.6;
  margin: 0 0 30px 0;
}

.container ul {
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit;
}

.container ul li {
  list-style-type: disc;
}

.container ol {
  padding: 0 0 0 40px;
}

.container ol li {
  list-style-type: decimal;
}

.container ol ul li {
  list-style: circle;
}

.container button {
  margin-top: 10px;
}

.container .checkbox {
  cursor: pointer;
}
.container .checkbox svg path {
  color: gray;
}

.loading {
  margin: 0 auto;
}