.passportDescriptionList {
  strong {
    color: #3D3D3D;
  }

  ul {
    font-weight: 300;
    font-size: 14px;
    padding-left: 40px;
  }

  li {
    list-style-type: disc;
  }

}
