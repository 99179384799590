.dashboardOIGSAM {
  display: flex;
  flex-direction: column;
}

.top {
  display: flex;
  align-items: center;
  margin-bottom: 31px;
}

.header,
.lineAfter {
  color: #007e8d;
  font-weight: 300;
  margin: 0;
  line-height: 1.4;
  font-family: "Roboto", sans-serif;
  text-rendering: optimizeLegibility;
  letter-spacing: 1px;
}

.lineAfter:after {
  content: "";
  display: block;
  margin: 15px 0;
  height: 1px;
  width: 150px;
  background: #007e8d;
}

.infoContainer {
  display: flex;
  justify-content: flex-start;
}

.info {
  max-width: 500px; /* Set to 500px to match target image, adjust to 100% for flexibility */
  padding-right: 40px;
}

.info a {
  display: unset !important;
  margin: unset !important;
  padding: unset !important;
  color: #007e8d !important;
  text-decoration: none !important;
  outline: none !important;
  border: none !important;
}

.info a:hover {
  color: #aa9b73 !important;
}

.info p,
.info li {
  color: #3d3d3d;
  letter-spacing: 1px;
  font-weight: 300;
  font-size: 14px;
  line-height: 1.6;
  text-rendering: optimizeLegibility;
  box-sizing: border-box;
  margin: 0;
  list-style-type: disc;
}

.tableContainer {
  max-width: 1000px; /* Set to 1000px to match target image, adjust to 100% for flexibility */
  margin-top: 30px;
}
